import React from "react";
import withDefaults from "../../../utils/with-defaults";

interface Props {
  className?: string;
}

const defaultProps = {
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<HTMLHeadingElement>, keyof Props>;
export type ModalSubtitleProps = Props & typeof defaultProps & NativeAttrs;

const ModalSubtitle: React.FC<ModalSubtitleProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <>
      <p
        className={`text-sm font-normal inline-block leading-normal h-6 text-center m-0 break-words text-gray-700 ${className}`}
        {...props}
      >
        {children}
      </p>
    </>
  );
};

const MemoModalSubtitle = React.memo(ModalSubtitle);

export default withDefaults(MemoModalSubtitle, defaultProps);
