import { AppProps, NextWebVitalsMetric } from "next/app";
import "../css/tailwind.css";
// @ts-ignore
import { AnalyticsProvider } from "use-analytics";
import Analytics from "analytics";
import AnalyticsInitializer from "@xtribe/shared/components/Analytics/AnalyticsInitializer";
// @ts-ignore
// import segmentPlugin from "@analytics/segment";
import { init } from "@xtribe/shared/utils/sentry";
// import * as Fathom from "fathom-client";

init();
// const fathomPlugin = {
//   name: "fathom",
//   page: ({ payload }: any) => {
//     if (Fathom) {
//       const url = payload.properties.url + payload.properties.path;
//       Fathom.trackPageview({ url });
//     }
//   },
//   track: ({ payload }: any) => {
//     if (Fathom) {
//       if (payload.event === "Studio Onboarding Start") {
//         Fathom.trackGoal("BOEW0U3J", 0);
//       } else if (payload.event === "Onboarding User Sign Up") {
//         Fathom.trackGoal("CKKC2CDE", 0);
//       } else if (payload.event === "Onboarding Team Create") {
//         Fathom.trackGoal("WV0LDXJJ", 0);
//       } else if (payload.event === "Onboarding Spaces Added") {
//         Fathom.trackGoal("6JY4D9KN", 0);
//       } else if (payload.event === "Onboarding Complete") {
//         Fathom.trackGoal("A5HJOJU7", 0);
//       } else if (payload.event === "Member Onboarding Start") {
//         Fathom.trackGoal("OGWNFFYR", 0);
//       }
//     }
//   },
// };
const analytics = Analytics({
  app: "xtribe",
  plugins: [
    // fathomPlugin,
    // segmentPlugin({ writeKey: "hCMsGlHAubSxqXLUoVG5J9m1yNigNiuC" }),
  ],
});
// analytics.plugins.disable("segment", () => null);

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   console.log(metric);
// }

export default function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: any }) {
  return (
    <AnalyticsProvider instance={analytics}>
      <AnalyticsInitializer>
        <Component {...pageProps} err={err} />
      </AnalyticsInitializer>
    </AnalyticsProvider>
  );
}
