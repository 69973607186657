import React, { useMemo } from "react";
import { ButtonTypes, NormalSizes } from "../../../utils/prop-types";
import withDefaults from "../../../utils/with-defaults";

interface Props {
  size?: NormalSizes;
  type?: ButtonTypes;
  color?: string;
  width?: string;
  height?: string;
}

const defaultProps = {
  size: "medium" as NormalSizes,
  type: "default" as ButtonTypes,
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type LoadingProps = Props & typeof defaultProps & NativeAttrs;

const getIconSize = (size: NormalSizes) => {
  const sizes: { [key in NormalSizes]: string } = {
    mini: "w-0.5 h-0.5",
    small: "w-1 h-1",
    medium: "w-1 h-1",
    large: "w-1.5 h-1.5",
  };
  return sizes[size];
};

const getIconBgColor = (type: ButtonTypes, color?: string) => {
  const colors: { [key in ButtonTypes]: string } = {
    default: "bg-gray-700",
    abort: "bg-gray-700",
    secondary: "bg-on-accent",
    success: "bg-on-primary",
    warning: "bg-white",
    error: "bg-white",
  };

  return color ? color : colors[type];
};

const Loading: React.FC<React.PropsWithChildren<LoadingProps>> = ({
  children,
  size,
  type,
  color,
}) => {
  const sizes = useMemo(() => getIconSize(size), [size]);
  const bgColor = useMemo(() => getIconBgColor(type, color), [type, color]);

  return (
    <div className="loading-container inline-flex items-center relative w-full h-full">
      <span className="loading absolute inset-0 w-full h-full flex justify-center items-center bg-transparent select-none">
        {children && (
          <label className="w-1.5 mr-2 text-gray-600">{children}</label>
        )}
        <i
          className={`rounded-full inline-block ${
            bgColor.includes("#") ? "" : bgColor
          } ${sizes}`}
          style={
            bgColor?.includes("#") ? { backgroundColor: bgColor } : undefined
          }
        />
        <i
          className={`rounded-full inline-block ${
            bgColor.includes("#") ? "" : bgColor
          } ${sizes}`}
          style={
            bgColor?.includes("#") ? { backgroundColor: bgColor } : undefined
          }
        />
        <i
          className={`rounded-full inline-block ${
            bgColor.includes("#") ? "" : bgColor
          } ${sizes}`}
          style={
            bgColor?.includes("#") ? { backgroundColor: bgColor } : undefined
          }
        />
      </span>
      <style jsx>{`
        label :global(*) {
          margin: 0;
        }
        i {
          margin: 0 1px;
          animation: loading-blink 1.4s infinite both;
        }
        i:nth-child(2) {
          animation-delay: 0.2s;
        }
        i:nth-child(3) {
          animation-delay: 0.4s;
        }
        @keyframes loading-blink {
          0% {
            opacity: 0.2;
          }

          20% {
            opacity: 1;
          }

          100% {
            opacity: 0.2;
          }
        }
      `}</style>
    </div>
  );
};

const MemoLoading = React.memo(Loading);

export default withDefaults(MemoLoading, defaultProps);
