import React from "react";
import withDefaults from "../../../utils/with-defaults";

interface Props {
  className?: string;
}

const defaultProps = {
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type ModalTitleProps = Props & typeof defaultProps & NativeAttrs;

const ModalTitle: React.FC<ModalTitleProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <>
      <h2
        className={`text-2xl leading-tight font-normal text-center m-0 inline-flex justify-center break-words capitalize text-foreground ${className}`}
        {...props}
      >
        {children}
      </h2>
    </>
  );
};

const MemoModalTitle = React.memo(ModalTitle);

export default withDefaults(MemoModalTitle, defaultProps);
