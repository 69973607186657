import React, { useCallback, useEffect, useMemo, useState } from "react";
import withDefaults from "../../../utils/with-defaults";
import { NormalSizes } from "../../../utils/prop-types";

interface ToggleEventTarget {
  checked: boolean;
}

export interface ToggleEvent {
  target: ToggleEventTarget;
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: React.ChangeEvent;
}

interface Props {
  checked?: boolean;
  initialChecked?: boolean;
  onChange?: (ev: ToggleEvent) => void;
  disabled?: boolean;
  size?: NormalSizes;
  className?: string;
}

const defaultProps = {
  size: "medium" as NormalSizes,
  disabled: false,
  initialChecked: false,
  className: "",
};

type NativeAttrs = Omit<React.LabelHTMLAttributes<any>, keyof Props>;
export type ToggleProps = Props & typeof defaultProps & NativeAttrs;

export type ToggleSize = {
  width: string;
  height: string;
};

const getSizes = (size: NormalSizes) => {
  const sizes: { [key in NormalSizes]: ToggleSize } = {
    mini: {
      width: "1.67rem",
      height: ".835rem",
    },
    small: {
      width: "1.67rem",
      height: ".835rem",
    },
    medium: {
      width: "1.75rem",
      height: ".875rem",
    },
    large: {
      width: "2.5rem",
      height: "1.25rem",
    },
  };
  return sizes[size];
};

const Toggle: React.FC<ToggleProps> = ({
  initialChecked,
  checked,
  disabled,
  onChange,
  size,
  className,
  ...props
}) => {
  const [selfChecked, setSelfChecked] = useState<boolean>(initialChecked);
  const { width, height } = useMemo(() => getSizes(size), [size]);

  const changeHandle = useCallback(
    (ev: React.ChangeEvent) => {
      if (disabled) return;
      const selfEvent: ToggleEvent = {
        target: {
          checked: !selfChecked,
        },
        stopPropagation: ev.stopPropagation,
        preventDefault: ev.preventDefault,
        nativeEvent: ev,
      };

      setSelfChecked(!selfChecked);
      onChange && onChange(selfEvent);
    },
    [disabled, selfChecked, onChange]
  );

  useEffect(() => {
    if (checked === undefined) return;
    setSelfChecked(checked);
  }, [checked]);

  return (
    <label
      className={`inline-block align-middle whitespace-nowrap select-none py-1 px-0 relative ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${className}`}
      {...props}
    >
      <input
        className={`overflow-hidden invisible h-0 w-0 opacity-0 absolute bg-transparent`}
        type="checkbox"
        disabled={disabled}
        checked={selfChecked}
        onChange={changeHandle}
      />
      <div
        className={`toggle border border-transparent bg-gray-200 p-0 relative ${
          selfChecked ? "checked" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <span className="inner bg-background" />
      </div>
      <style jsx>{`
        label {
          -webkit-tap-highlight-color: transparent;
        }

        input {
          z-index: -1;
        }

        .toggle {
          height: ${height};
          width: ${width};
          border-radius: ${height};
          transition-delay: 0.12s;
          transition-duration: 0.2s;
          transition-property: background, border;
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }

        .inner {
          width: calc(${height} - 2px);
          height: calc(${height} - 2px);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 1px;
          box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0,
            rgba(0, 0, 0, 0.1) 0 1px 3px 0;
          transition: left 280ms cubic-bezier(0, 0, 0.2, 1);
          border-radius: 50%;
        }

        .checked > .inner {
          left: calc(100% - (${height} - 2px));
          box-shadow: none;
        }
      `}</style>
    </label>
  );
};

const MemoToggle = React.memo(Toggle);

export default withDefaults(MemoToggle, defaultProps);
