import React from "react";
import withDefaults from "../../../utils/with-defaults";

interface Props {
  className?: string;
}

const defaultProps = {
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>;
export type ModalContentProps = Props & typeof defaultProps & NativeAttrs;

const ModalContent: React.FC<ModalContentProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <>
      <div
        className={`content -mx-4 pt-4 px-4 pb-2 relativ text-left ${className}`}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

const MemoModalContent = React.memo(ModalContent);

export default withDefaults(MemoModalContent, defaultProps);
