import React, { ReactNode } from "react";
import { NormalSizes } from "../../../utils/prop-types";
import { ButtonGroupConfig } from "../ButtonGroup/button-group-context";
import { ButtonProps } from "./button";
import ButtonIcon from "./button-icon";
import { getPaddingForAutoMode } from "./styles";

export const getButtonChildrenWithIcon = (
  auto: boolean,
  iconButton: boolean,
  size: NormalSizes,
  children: ReactNode,
  icons: {
    icon?: React.ReactNode;
    iconRight?: React.ReactNode;
  }
) => {
  const { icon, iconRight } = icons;
  const hasIcon = icon || iconRight;
  const isRight = Boolean(iconRight);
  const paddingForAutoMode =
    hasIcon || auto || size === "mini"
      ? getPaddingForAutoMode(size, isRight)
      : 0;
  if (!hasIcon) return <div className="text">{children}</div>;
  if (React.Children.count(children) === 0) {
    return (
      <ButtonIcon
        isRight={isRight}
        isSingle
        size={size}
        auto={auto}
        iconButton={iconButton}
      >
        {hasIcon}
      </ButtonIcon>
    );
  }
  return (
    <>
      <ButtonIcon
        isRight={isRight}
        size={size}
        auto={auto}
        iconButton={iconButton}
      >
        {hasIcon}
      </ButtonIcon>
      <div className={`text ${paddingForAutoMode}`}>
        {children}
        <style jsx>{`
          .left {
            padding-left: ${paddingForAutoMode};
          }
          .right {
            padding-right: ${paddingForAutoMode};
          }
        `}</style>
      </div>
    </>
  );
};

export const filterPropsWithGroup = (
  props: React.PropsWithChildren<ButtonProps>,
  config: ButtonGroupConfig
): ButtonProps => {
  if (!config.isButtonGroup) return props;
  return {
    ...props,
    auto: true,
    shadow: false,
    ghost: config.ghost || props.ghost,
    size: config.size || props.size,
    type: config.type || props.type,
    disabled: config.disabled || props.disabled,
  };
};
