import React, {
  MouseEvent,
  PropsWithoutRef,
  RefAttributes,
  useImperativeHandle,
  useRef,
} from "react";
import { useModalContext } from "./modal-context";
import Button, { ButtonProps } from "../Button/button";

type ModalActionEvent = MouseEvent<HTMLButtonElement> & {
  close: () => void;
};

interface Props {
  className?: string;
  passive?: boolean;
  disabled?: boolean;
  onClick?: (event: ModalActionEvent) => void;
}

const defaultProps = {
  className: "",
  passive: false,
  disabled: false,
};

export type ModalActionProps = Props &
  typeof defaultProps &
  Omit<ButtonProps, keyof Props>;

const ModalAction = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ModalActionProps>
>(
  (
    { className, children, onClick, passive, disabled, ...props },
    ref: React.Ref<HTMLButtonElement | null>
  ) => {
    const btnRef = useRef<HTMLButtonElement>(null);
    const { close } = useModalContext();
    useImperativeHandle(ref, () => btnRef.current);

    const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
      if (disabled) return;
      const actionEvent = Object.assign({}, event, {
        close: () => close && close(),
      });
      onClick && onClick(actionEvent);
    };

    const overrideProps = {
      ...props,
      effect: false,
      ref: btnRef,
    };

    return (
      <Button
        className={`${className}`}
        onClick={clickHandler}
        disabled={disabled}
        {...overrideProps}
        modalBtn
      >
        {children}
      </Button>
    );
  }
);

type ModalActionComponent<T, P = {}> = React.ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
>;

type ComponentProps = Partial<typeof defaultProps> &
  Omit<Props, keyof typeof defaultProps> &
  Partial<Omit<ButtonProps, keyof Props>>;

ModalAction.defaultProps = defaultProps;

export default ModalAction as ModalActionComponent<
  HTMLButtonElement,
  ComponentProps
>;
