import { useEffect, useState } from "react";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import useModal from "../lib/Modal/use-modal";
import AnalyticsModal from "./AnalyticsModal";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import * as Fathom from "fathom-client";

export interface CookieProps {
  [key: string]: boolean;
}

const AnalyticsInitializer: React.FC = ({ children }) => {
  const { plugins, page } = useAnalytics();
  const router = useRouter();
  const [enabled, setEnabled] = useState(true);
  const {
    visible: showCookieBanner,
    setVisible: setShowCookieBanner,
    bindings,
  } = useModal();

  useEffect(() => {
    const isDev = window.location.host.includes("localhost");
    if (!isDev) {
      const cookieItem = window.localStorage.getItem("cookieConsent");
      const validItem = window.localStorage.getItem("cookieValidUntil");
      const cookieConsent = cookieItem ? JSON.parse(cookieItem) : null;
      const cookieValidUntil = validItem ? JSON.parse(validItem) : null;
      // if (cookieConsent) {
      //   if (cookieConsent?.marketing === true) {
      //     plugins.enable("segment");
      //     setEnabled(true);
      //   } else if (!cookieValidUntil || cookieValidUntil < dayjs().unix()) {
      //     setShowCookieBanner(true);
      //   } else {
      //     page({ path: window.location.pathname });
      //   }
      // } else {
      //   setShowCookieBanner(true);
      // }
    }
  }, []);

  useEffect(() => {
    // Fathom.load("XXMIXFEE", {
    //   includedDomains: ["xtribe.app"],
    //   auto: false,
    // });
    const handleRouteChange = (path: string) => {
      page({ path });
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (enabled) {
      page({ path: window.location.pathname });
    }
  }, [enabled]);

  const onClose = (acceptedCookies: CookieProps) => {
    setShowCookieBanner(false);
    if (acceptedCookies.marketing) {
      plugins.enable("segment");
    }
    setEnabled(true);
    window.localStorage.setItem(
      "cookieConsent",
      JSON.stringify(acceptedCookies)
    );
    window.localStorage.setItem(
      "cookieValidUntil",
      `${dayjs().add(14, "day").unix()}`
    );
  };
  return (
    <>
      <AnalyticsModal {...bindings} onClose={onClose} />
      {children}
    </>
  );
};

export default AnalyticsInitializer;
