export const hexToRgb = (color: string): [number, number, number] => {
  const fullReg = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const full = color.replace(
    fullReg,
    (_, r, g, b) => `${r}${r}${g}${g}${b}${b}`
  );
  const values = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(full);
  if (!values) {
    throw new Error(`Geist UI: Unsupported ${color} color.`);
  }
  return [
    Number.parseInt(values[1], 16),
    Number.parseInt(values[2], 16),
    Number.parseInt(values[3], 16),
  ];
};

export const colorToRgbValues = (color: string) => {
  if (color.charAt(0) === "#") return hexToRgb(color);

  const safeColor = color.replace(/ /g, "");
  const colorType = color.substr(0, 4);

  const regArray = safeColor.match(/\((.+)\)/);
  if (!colorType.startsWith("rgb") || !regArray) {
    throw new Error(`Geist UI: Only support ["RGB", "RGBA", "HEX"] color.`);
  }

  return regArray[1].split(",").map((str) => Number.parseFloat(str));
};

export const addColorAlpha = (color: string, alpha: number) => {
  if (!/^#|rgb|RGB/.test(color)) return color;
  const [r, g, b] = colorToRgbValues(color);
  const safeAlpha = alpha > 1 ? 1 : alpha < 0 ? 0 : alpha;
  return `rgba(${r}, ${g}, ${b}, ${safeAlpha})`;
};

export const getCorrectTextColor = (hex: string) => {
  const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

  const hRed = hexToR(hex);
  const hGreen = hexToG(hex);
  const hBlue = hexToB(hex);

  function hexToR(h: string) {
    return parseInt(cutHex(h).substring(0, 2), 16);
  }
  function hexToG(h: string) {
    return parseInt(cutHex(h).substring(2, 4), 16);
  }
  function hexToB(h: string) {
    return parseInt(cutHex(h).substring(4, 6), 16);
  }
  function cutHex(h: string) {
    return h.charAt(0) == "#" ? h.substring(1, 7) : h;
  }

  const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
  if (cBrightness > threshold) {
    return "#000000";
  } else {
    return "#ffffff";
  }
};
