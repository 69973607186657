import React, {
  useRef,
  useState,
  MouseEvent,
  useMemo,
  useImperativeHandle,
  PropsWithoutRef,
  RefAttributes,
  ElementType,
} from "react";
import ButtonDrip from "./button.drip";
import ButtonLoading from "./button-loading";
import { ButtonTypes, NormalSizes } from "../../../utils/prop-types";
import { filterPropsWithGroup, getButtonChildrenWithIcon } from "./utils";
import { useButtonGroupContext } from "../ButtonGroup/button-group-context";
import {
  getButtonColors,
  getButtonCursor,
  getButtonDripColor,
  getButtonSize,
} from "./styles";

interface Props {
  elementType?: ElementType;
  href?: string;
  target?: string;
  type?: ButtonTypes;
  size?: NormalSizes;
  ghost?: boolean;
  modalBtn?: boolean;
  drawerBtn?: boolean;
  iconButton?: boolean;
  homepageBtn?: boolean;
  loading?: boolean;
  shadow?: boolean;
  auto?: boolean;
  effect?: boolean;
  disabled?: boolean;
  htmlType?: React.ButtonHTMLAttributes<any>["type"];
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const defaultProps = {
  elementType: "button" as ElementType,
  type: "default" as ButtonTypes,
  size: "medium" as NormalSizes,
  htmlType: "button" as React.ButtonHTMLAttributes<any>["type"],
  ghost: false,
  modalBtn: false,
  drawerBtn: false,
  homepageBtn: false,
  iconButton: false,
  loading: false,
  shadow: false,
  auto: false,
  effect: true,
  disabled: false,
  className: "",
};

type NativeAttrs = Omit<React.ButtonHTMLAttributes<any>, keyof Props>;
export type ButtonProps = Props & typeof defaultProps & NativeAttrs;

const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>(({ ...btnProps }, ref: React.Ref<HTMLButtonElement | null>) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => buttonRef.current);

  const [dripShow, setDripShow] = useState<boolean>(false);
  const [dripX, setDripX] = useState<number>(0);
  const [dripY, setDripY] = useState<number>(0);
  const groupConfig = useButtonGroupContext();
  const filteredProps = filterPropsWithGroup(btnProps, groupConfig);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    children,
    elementType: ElementType,
    target,
    href,
    disabled,
    type,
    loading,
    shadow,
    ghost,
    modalBtn,
    drawerBtn,
    homepageBtn,
    iconButton,
    effect,
    onClick,
    auto,
    size,
    icon,
    htmlType,
    iconRight,
    className,
    ...props
  } = filteredProps;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const colors = useMemo(() => getButtonColors(filteredProps), [filteredProps]);

  const cursor = useMemo(
    () => getButtonCursor(disabled, loading),
    [disabled, loading]
  );
  const sizeClass = useMemo(
    () => getButtonSize(size, auto, iconButton),
    [size, auto]
  );
  const dripColor = useMemo(
    () => getButtonDripColor(filteredProps),
    [filteredProps]
  );

  /* istanbul ignore next */
  const dripCompletedHandle = () => {
    setDripShow(false);
    setDripX(0);
    setDripY(0);
  };

  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading) return;
    const showDrip = !shadow && !ghost && effect;
    /* istanbul ignore next */
    if (showDrip && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDripShow(true);
      setDripX(event.clientX - rect.left);
      setDripY(event.clientY - rect.top);
    }

    onClick && onClick(event);
  };

  const childrenWithIcon = useMemo(
    () =>
      getButtonChildrenWithIcon(auto, iconButton, size, children, {
        icon,
        iconRight,
      }),
    [auto, size, children, icon, iconRight]
  );
  const splitColors = colors.split(" ");

  let textColors = "";
  splitColors.forEach((el) => {
    if (el.includes("text-")) {
      textColors += " " + el.replace("text", "bg");
    }
  });

  let buttonStyles = ``;

  if (modalBtn) {
    buttonStyles =
      "modalBtn text-xs border-0 border-gray-200 bg-background flex items-center justify-center flex-1 h-full rounded-none min-w-0 " +
      (disabled ? "text-gray-400" : "");
  } else if (drawerBtn) {
    buttonStyles = `rounded-md border flex items-center justify-center flex-1 h-full min-w-0 ${sizeClass} ${colors}`;
  } else if (homepageBtn) {
    buttonStyles = "rounded-full border " + sizeClass + " " + colors;
  } else {
    buttonStyles = "rounded-md border " + sizeClass + " " + colors;
  }

  return (
    <button
      ref={buttonRef}
      type={htmlType}
      className={`btn ${cursor} outline-none ${
        shadow ? "shadow-sm hover:shadow" : "none"
      } transition-all ease-out duration-200 leading-normal  box-border inline-block font-normal select-none outline-none capitalize  justify-center text-center whitespace-nowrap relative overflow-hidden ${buttonStyles}
      focus:outline-none
       ${className}`}
      disabled={disabled}
      onClick={clickHandler}
      {...props}
    >
      {loading && <ButtonLoading type={type} ghost={ghost} />}
      {childrenWithIcon}
      {dripShow && (
        <ButtonDrip
          x={dripX}
          y={dripY}
          color={dripColor}
          onCompleted={dripCompletedHandle}
        />
      )}
      <style jsx>{`
        .btn :global(.text) {
          position: relative;
          z-index: 1;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height: inherit;
          top: -1;
        }
        .btn :global(.text p),
        .btn :global(.text pre),
        .btn :global(.text div) {
          margin: 0;
        }
      `}</style>
    </button>
  );
});

type ButtonComponent<T, P = {}> = React.ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
>;
type ComponentProps = Partial<typeof defaultProps> &
  Omit<Props, keyof typeof defaultProps> &
  NativeAttrs;

Button.defaultProps = defaultProps;

export default React.memo(Button) as ButtonComponent<
  HTMLButtonElement,
  ComponentProps
>;
