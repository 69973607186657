import { NormalSizes, ButtonTypes } from "../../../utils/prop-types";
import { ButtonProps } from "./button";
import { addColorAlpha } from "../../../utils/color";

export interface ButtonColorGroup {
  bg: string;
  border: string;
  color: string;
}

export const getButtonGhostColors = (type: ButtonTypes): string | null => {
  const colors: { [key in ButtonTypes]?: string } = {
    secondary:
      "bg-on-accent border-accent text-accent hover:bg-accent hover:border-on-accent hover:text-on-accent",
    success:
      "bg-on-primary border-primary text-primary hover:bg-primary hover:border-on-primary hover:text-white",
    warning:
      "bg-background border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:border-background hover:text-white",
    error:
      "bg-background border-red-500 text-red-500 hover:bg-red-500 hover:border-background hover:text-white",
  };

  return colors[type] || null;
};

export const getButtonColors = (props: ButtonProps): string => {
  const { type, disabled, ghost, loading } = props;
  const colors: { [key in ButtonTypes]: string } = {
    default:
      "bg-background border-background-soft text-foreground-soft hover:bg-background hover:border-background hover:text-foreground",
    secondary:
      "bg-accent border-accent text-on-accent hover:bg-background hover:border-accent hover:text-accent",
    success:
      "bg-primary border-primary text-on-primary hover:bg-background hover:border-primary hover:text-primary",
    warning:
      "bg-yellow-500 border-yellow-500 text-white hover:bg-background hover:border-yellow-500 hover:text-yellow-500",
    error:
      "bg-red-500 border-red-500 text-white hover:bg-background hover:border-red-500 hover:text-red-500",
    abort:
      "bg-transparent border-transparent text-foreground-soft hover:bg-gray-100 hover:border-transparent hover:text-gray-600",
  };
  if (disabled) return "bg-gray-50 border-gray-200 text-gray-400";
  const defaultColor = colors.default;
  if (ghost) return getButtonGhostColors(type) || defaultColor;
  return colors[type] || defaultColor;
};
export interface ButtonCursorGroup {
  cursor: string;
  events: string;
}

export const getButtonCursor = (
  disabled: boolean,
  loading: boolean
): string => {
  if (disabled) return "cursor-not-allowed pointer-events-auto";
  if (loading) return "cursor-default pointer-events-none";
  return "cursor-pointer pointer-events-auto";
};

export type ButtonSizeGroup = {
  height: string;
  width: string;
  padding: string;
  minWidth: string;
  fontSize: string;
};

export const getButtonSize = (
  size: NormalSizes = "medium",
  auto: boolean,
  iconButton: boolean
): string => {
  const defaultLayout = "h-11 w-auto px-6 text-sm min-w-4xs";

  const autoLayouts: { [key in NormalSizes]: string } = {
    medium: "h-11 px-4 text-sm w-min w-auto",
    mini: "h-6 px-2 text-xs w-min w-auto",
    small: "h-8 px-3 text-sm w-min w-auto",
    large: "h-12 px-6 text-base w-min w-auto",
  };
  const iconButtonLayouts: { [key in NormalSizes]: string } = {
    medium: "h-11 px-3 w-auto text-sm w-min w-auto",
    mini: "h-6 px-1 text-xs w-min w-auto",
    small: "h-8 px-2 text-sm w-min w-auto",
    large: "h-12 px-4 text-base w-min w-auto",
  };
  const layouts: { [key in NormalSizes]: string } = {
    mini: "h-6 px-5 text-xs min-w-6xs",
    small: "h-8 px-5 text-sm min-w-5xs",
    medium: defaultLayout,
    large: "h-12 px-7 text-base min-w-2xs",
  };

  if (auto) return autoLayouts[size] || defaultLayout;
  if (iconButton) return iconButtonLayouts[size] || defaultLayout;

  return layouts[size] || defaultLayout;
};
export const getPaddingForAutoMode = (
  size: NormalSizes = "medium",
  isRight: boolean
): string => {
  const defaultPaddings = `${isRight ? "pr-7" : "pl-7"}`;

  const paddings: { [key in NormalSizes]: string } = {
    medium: defaultPaddings,
    mini: `${isRight ? "pr-5" : "pl-5"}`,
    small: `${isRight ? "pr-6" : "pl-6"}`,
    large: `${isRight ? "pr-8" : "pl-8"}`,
  };

  return paddings[size] || defaultPaddings;
};

export const getButtonIconStyles = (
  size: NormalSizes = "medium",
  auto: boolean,
  iconButton: boolean,
  isRight: boolean
): string => {
  const defaultLayout = `h-5 ${
    isRight ? "right-6 left-auto" : "right-auto left-6"
  }`;

  const autoLayouts: { [key in NormalSizes]: string } = {
    medium: `h-5 ${isRight ? "right-4 left-auto" : "right-auto left-4"}`,
    mini: `h-3 ${isRight ? "right-2 left-auto" : "right-auto left-2"}`,
    small: `h-4 ${isRight ? "right-3 left-auto" : "right-auto left-3"}`,
    large: `h-6 ${isRight ? "right-6 left-auto" : "right-auto left-6"}`,
  };
  const iconButtonLayouts: { [key in NormalSizes]: string } = {
    medium: `h-5 ${isRight ? "right-3 left-auto" : "right-auto left-3"}`,
    mini: `h-3 ${isRight ? "right-1 left-auto" : "right-auto left-1"}`,
    small: `h-4 ${isRight ? "right-2 left-auto" : "right-auto left-2"}`,
    large: `h-6 ${isRight ? "right-4 left-auto" : "right-auto left-4"}`,
  };
  const layouts: { [key in NormalSizes]: string } = {
    mini: `h-3 ${isRight ? "right-5 left-auto" : "right-auto left-5"}`,
    small: `h-4 ${isRight ? "right-5 left-auto" : "right-auto left-5"}`,
    medium: defaultLayout,
    large: `h-6 ${isRight ? "right-7 left-auto" : "right-auto left-7"}`,
  };

  if (auto) return autoLayouts[size] || defaultLayout;
  if (iconButton) return iconButtonLayouts[size] || defaultLayout;

  return layouts[size] || defaultLayout;
};

export const getButtonDropdownIconStyles = (
  size: NormalSizes = "medium"
): string => {
  const defaultLayout = `20px`;
  const layouts: { [key in NormalSizes]: string } = {
    mini: `12px`,
    small: `16px`,
    medium: defaultLayout,
    large: `24px`,
  };
  return layouts[size] || defaultLayout;
};

export const getButtonDripColor = (props: ButtonProps) => {
  const { type } = props;
  return addColorAlpha("#eaeaea", 0.65);
};
