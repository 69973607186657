import React, { MouseEvent, useCallback } from "react";
import withDefaults from "../../../utils/with-defaults";
import CSSTransition from "./css-transition";
import useCurrentState from "../../../utils/use-current-state";

interface Props {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  visible?: boolean;
  width?: string;
}

const defaultProps = {
  onClick: () => {},
  visible: false,
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type BackdropProps = Props & typeof defaultProps & NativeAttrs;

const Backdrop: React.FC<React.PropsWithChildren<BackdropProps>> = React.memo(
  ({ children, onClick, visible, width, ...props }) => {
    const [, setIsContentMouseDown, IsContentMouseDownRef] =
      useCurrentState(false);
    const clickHandler = (event: MouseEvent<HTMLElement>) => {
      if (IsContentMouseDownRef.current) return;
      onClick && onClick(event);
    };
    const childrenClickHandler = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      },
      []
    );
    const mouseUpHandler = () => {
      if (!IsContentMouseDownRef.current) return;
      const timer = setTimeout(() => {
        setIsContentMouseDown(false);
        clearTimeout(timer);
      }, 0);
    };

    return (
      <CSSTransition name="backdrop-wrapper" visible={visible} clearTime={300}>
        <>
          <div
            className="backdrop fixed inset-0 overflow-auto box-border text-center"
            onClick={clickHandler}
            onMouseUp={mouseUpHandler}
            {...props}
          >
            <div className="layer fixed inset-0 w-full h-full bg-black pointer-events-none transition-opacity duration-300 ease-in-out" />
            <div
              onClick={childrenClickHandler}
              className="content relative outline-none my-5 mx-auto align-middle inline-block"
              onMouseDown={() => setIsContentMouseDown(true)}
            >
              {children}
            </div>
          </div>
          <style jsx>{`
            .backdrop {
              z-index: 1000;
              -webkit-overflow-scrolling: touch;
            }

            .content {
              z-index: 1001;
              max-width: 95%;
              width: ${width};
            }

            .backdrop:before {
              display: inline-block;
              width: 0;
              height: 100%;
              vertical-align: middle;
              content: "";
            }

            .layer {
              opacity: 0.25;
              z-index: 1000;
            }

            .backdrop-wrapper-enter .layer {
              opacity: 0;
            }

            .backdrop-wrapper-enter-active .layer {
              opacity: 0.25;
            }

            .backdrop-wrapper-leave .layer {
              opacity: 0.25;
            }

            .backdrop-wrapper-leave-active .layer {
              opacity: 0;
            }
          `}</style>
        </>
      </CSSTransition>
    );
  }
);

export default withDefaults(Backdrop, defaultProps);
