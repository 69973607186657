import React from "react";

const ModalActions: React.FC<React.PropsWithChildren<{}>> = ({
  children,
  ...props
}) => {
  return (
    <>
      <div className="h-14 flex-shrink-0" />
      <footer
        className="flex overflow-hidden w-full h-14 absolute bottom-0 left-0 right-0 border-t border-gray-200 rounded-bl-md rounded-br-md"
        {...props}
      >
        {children}
      </footer>
      <style jsx>{`
        footer > :global(button.btn + button.btn) {
          border-left: 1px solid #eaeaea;
        }
      `}</style>
    </>
  );
};

const MemoModalActions = React.memo(ModalActions);

export default MemoModalActions;
