import React from "react";
import { ButtonTypes } from "../../../utils/prop-types";
import Loading from "../Loading";

interface Props {
  type: ButtonTypes;
  ghost?: boolean;
}

const ButtonLoading: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  ghost,
}) => {
  return (
    <div className="btn-loading absolute inset-0 w-full h-full">
      <Loading type={ghost ? "default" : type} />
      <style jsx>{`
        .btn-loading {
          background-color: inherit;
          z-index: 20;
        }
      `}</style>
    </div>
  );
};

export default React.memo(ButtonLoading);
