import React from "react";
import { NormalSizes } from "../../../utils/prop-types";
import withDefaults from "../../../utils/with-defaults";
import { getButtonIconStyles } from "./styles";

interface Props {
  isRight?: boolean;
  isSingle?: boolean;
  auto: boolean;
  iconButton: boolean;
  size: NormalSizes;
  className?: string;
}

const defaultProps = {
  isRight: false,
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type ButtonIconProps = Props & typeof defaultProps & NativeAttrs;

const ButtonIcon: React.FC<React.PropsWithChildren<ButtonIconProps>> = ({
  isRight,
  isSingle,
  size,
  auto,
  iconButton,
  children,
  className,
  ...props
}) => {
  const styles = getButtonIconStyles(size, auto, iconButton, isRight);
  return (
    <span
      className={`icon top-1/2 flex items-center justify-center z-10 ${styles} ${
        isSingle
          ? "single static transform-none"
          : "absolute transform -translate-y-1/2"
      } ${className}`}
      {...props}
    >
      {children}
      <style jsx>{`
        .icon :global(svg) {
          background: transparent;
          height: inherit;
          width: inherit;
        }
      `}</style>
    </span>
  );
};

const MemoButtonIcon = React.memo(ButtonIcon);

export default withDefaults(MemoButtonIcon, defaultProps);
