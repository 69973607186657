import Modal from "../lib/Modal";
import Toggle from "../lib/toggle";
import ChevronRight from "@geist-ui/react-icons/chevronRight";
import Button from "../lib/Button";
import { useCallback, useState } from "react";
import { CookieProps } from "./AnalyticsInitializer";

type Props = {
  open: boolean;
  onClose: (cookieProps: CookieProps) => void;
};

const cookies: CookieProps = {
  general: true,
  marketing: false,
  analysis: false,
};

const AnalyticsModal: React.FC<Props> = ({ open, onClose }) => {
  const [acceptedCookies, setAcceptedCookies] = useState<CookieProps>({
    ...cookies,
  });

  const toggleCookies = useCallback(
    (value: string) => {
      setAcceptedCookies((current) => {
        return { ...current, [value]: !current[value] };
      });
    },
    [acceptedCookies]
  );

  const onCloseModal = (acceptAll?: boolean) => {
    if (acceptAll) {
      const allCookies: CookieProps = {
        general: true,
        marketing: true,
        analysis: true,
      };
      onClose(allCookies);
    } else {
      onClose(acceptedCookies);
    }
  };

  return (
    <div>
      <Modal open={open} width="380px" disableBackdropClick={true}>
        <Modal.Title>Cookie Einstellungen</Modal.Title>
        <Modal.Content>
          <p className="text-sm text-center mb-8">
            Wir nutzen Cookies auf unserer Website. Einige von ihnen sind
            essenziell, während andere uns helfen, diese Website und deine
            Erfahrung zu verbessern.
          </p>
          <div className="my-4 flex justify-between">
            <div className="flex items-center">
              <ChevronRight size={20} />

              <p className="ml-2 text-sm">Notwendige Cookies</p>
            </div>
            <Toggle size="large" checked={true} />
          </div>
          <div className="my-4 flex justify-between">
            <div className="flex items-center">
              <ChevronRight size={20} />

              <p className="ml-2 text-sm">Cookies für Marketing</p>
            </div>
            <Toggle
              size="large"
              onChange={(e) => toggleCookies("marketing")}
              checked={acceptedCookies.marketing}
            />
          </div>
          <div className="my-4 flex justify-between">
            <div className="flex items-center">
              <ChevronRight size={20} />

              <p className="ml-2 text-sm">Cookies für Analyse</p>
            </div>
            <Toggle
              size="large"
              onChange={(e) => toggleCookies("analysis")}
              checked={acceptedCookies.analysis}
            />
          </div>
          <div className="flex flex-col items-stretch mt-8">
            <Button
              type="success"
              size="large"
              className="mb-4"
              onClick={() => onCloseModal(true)}
            >
              Alle Cookies Akzeptieren
            </Button>
            <Button
              type="default"
              ghost
              size="large"
              onClick={() => onCloseModal()}
            >
              Nur Auswahl Speichern
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default AnalyticsModal;
